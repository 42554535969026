const firebaseConfig = {
  apiKey: "AIzaSyBFROSYebvyH9wYwIPvl2AeiCFkPPpvv-8",
  authDomain: "survey-backend-70299.firebaseapp.com",
  databaseURL: "https://survey-backend-70299-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "survey-backend-70299",
  storageBucket: "survey-backend-70299.appspot.com",
  messagingSenderId: "83445893881",
  appId: "1:83445893881:web:05487533d16000c520b028",
  measurementId: "G-NGL342BKBL"
};
export default firebaseConfig;

